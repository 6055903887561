<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
    <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
      <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
        <Form :columns="addColumns" :type="typeForm" @submit="submit" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  goodsTypeList,
  schoolGoodsInboundList,
  stockGoodsInboundDel,
  stockGoodsInboundSubmit
} from '@/apis/common'
import ListToolbar from '@/components/system/listToolbar.vue'
import listTable from '@/components/system/listTable.vue'
import formSee from '@/components/system/formSee.vue'
import Form from '@/components/system/form.vue'

export default {
  name: 'inbound',
  components: { Form, formSee, listTable, ListToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      toolbar: [
        {type: 'input', label: '商品名称', model: 'goods_name', value: ''},
        {type: 'select', label: '商品类型', model: 'type_id', value: '', options: []},
        {type: 'date', label: '开始日期', model: 'start_date', value: ''},
        {type: 'date', label: '结束日期', model: 'end_date', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      formData: {
        goods_name: '',
        type_id: '',
        start_date: '',
        end_date: '',
        goods_id: ''
      },
      list: [],
      columns: [
        {label: '入库记录ID', prop: 'id', width: 100, type: 'text'},
        {label: '商品名称', prop: 'goods_name', width: 200, type: 'text'},
        {label: '商品分类', prop: 'type_name', width: 100, type: 'text'},
        {label: '数量', prop: 'num', width: 100, type: 'text'},
        {label: '标准进货价', prop: 'standard_buying_price', width: 100, type: 'text'},
        {label: '实际进货价', prop: 'buying_price', width: 100, type: 'text'},
        {label: '品牌', prop: 'brand', width: 100, type: 'text'},
        {label: '入库时间', prop: 'in_date', width: 150, type: 'text'},
        {label: '添加时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 100, type: 'but', arr: [
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)}
          ]}
      ],
      typeForm: 'add',
      formDrawerVisible: false,
      addColumns: [
        {label: '商品', prop: 'goods_id', value: '', type: 'goods'},
        {label: '实际进货价', prop: 'buying_price', value: '', type: 'input'},
        {label: '入库数量', prop: 'num', value: '', type: 'input'},
        {label: '入库时间', prop: 'in_date', value: '', type: 'date'},
        {label: '备注信息', prop: 'note', value: '', type: 'textarea'},
      ],
      addFormData: {}
    }
  },
  mounted() {
    this.getSchoolGoodsInboundList()
    this.getGoodsTypeList()
  },
  methods:{
    // 删除
    del(row) {
      this.addFormData = row
      this.$confirm('确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        stockGoodsInboundDel({
          admin_id: this.info.id,
          school_id: this.info.agency_school_id,
          inbound_id: row.id
        }).then(res => {
          this.$message.success('删除成功')
          this.getSchoolGoodsInboundList()
        })
      }).catch(() => {})
    },
    submit(e,n) {
      let form = {
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...e
      }
      stockGoodsInboundSubmit(form).then(res => {
        this.formDrawerVisible = false
        this.$message.success('操作成功')
        this.getSchoolGoodsInboundList()
      })
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    // 分页
    pageChange(e) {
      this.page = e
      this.getSchoolGoodsInboundList()
    },
    toolTap(data) {
      this.formData = data
      this.page = 1
      this.getSchoolGoodsInboundList()
    },
    getGoodsTypeList() {
      goodsTypeList({
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
      }).then(res => {
        this.toolbar[1].options = res.data.data.map(x => {
          return {label: x.name, value: x.id}
        })
      })
    },
    getSchoolGoodsInboundList() {
      this.loading = true
      schoolGoodsInboundList({
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id,
        school_id: this.info.agency_school_id,
        ...this.formData
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
